#navbar {
  position: fixed;
  top: $bg-padding-y;
  left: calc(#{$sidebar-width} + #{2 * $bg-padding-x});
  height: $navbar-height;
  width: calc(100vw - #{$sidebar-width} - #{3 * $bg-padding-x});
  background: $bg-primary;
  z-index: 1000;
  border-radius: 15px;
  display: flex;
}

#usermenu,
#restaurantmenu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 200px;
  background: white;
  box-shadow: $base-elevation;
  border-radius: 4px;
  padding: 0.5rem;

  p {
    margin: 0;
  }
}

@media (max-width: breakpoint("small-screen")) {
  #navbar {
    left: calc(#{$sidebar-width} + #{2 * $bg-padding-x} - 5%);
    width: calc(100vw - #{$sidebar-width} - #{3 * $bg-padding-x} + 5%);
  }
}

@media (max-width: breakpoint("laptop")) {
  #navbar {
    display: none;
  }
  #restaurantmenu {
    top: 85px;
    width: 80%;
  }
}

@media (max-width: breakpoint("tablet")) {
  #navbar {
    display: none;
  }
  #restaurantmenu {
    top: 85px;
    width: 80%;
  }
}

@media (max-width: breakpoint("phone")) {
  #navbar {
    display: none;
  }
  #restaurantmenu {
    top: 85px;
    width: 80%;
  }
}
