.tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag {
    font-size: $font-size-p5;    
}

@each $color-name, $color-value in $colors {
    .tag-#{$color-name} {
        border-radius: 5px;
        background-color: $color-value;
        padding: 3px;
        color: $white;
    }
}

@each $color-name, $color-value in $pastel-colors {
    .tag-#{$color-name} {
        border-radius: 5px;
        background-color: $color-value;
        padding: 3px;
        color: $black;
    }
}
