#dashboard {
  display: flex;
  max-height: 100vh;
  overflow: hidden;
  gap: $bg-padding-x;
  margin-top: calc(#{$navbar-height} + #{$bg-padding-y});
  margin-left: calc(#{$sidebar-width} + #{$bg-padding-x});
  min-height: calc(100vh - #{3 * $bg-padding-y} - #{$navbar-height});
  max-height: calc(100vh - #{3 * $bg-padding-y} - #{$navbar-height});
  min-width: calc(100vw - #{$sidebar-width} - #{3 * $bg-padding-x});
}

.dashboard-content {
  flex-grow: 1;
  border-radius: 15px;
  padding: 1.5rem 1rem 0.5rem 1rem;
  background-color: palette("pastel-light");
}

.dashboard-preview {
  display: flex;
  flex-direction: column;
  gap: $bg-padding-y;
  flex-grow: 1;

  .preview-section {
    display: flex;
    flex-grow: 1;
    border-radius: 15px;
    background-color: palette("pastel-light");
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    align-items: center;
    justify-content: center;
  }

  .publish-section {
    border-radius: 15px;
    background-color: palette("pastel-light");
    padding: 1.5rem 1.5rem 1.5rem 1rem;
  }
}

.table-container {
  max-height: calc(100vh - #{9.5 * $bg-padding-y} - #{$navbar-height});
  overflow-y: auto;

  &.not-scrollable {
    max-height: fit-content !important;
    overflow-y: hidden !important;
  }
}

#settings {
  .dashboard-content {
    height: fit-content;
    min-height: fit-content;
  }

  .img-settings-container {
    padding: 60px 0 30px 0;
  }

  .settings-content {
    height: 90%;
    overflow-y: auto !important;
  }
}

@media (max-width: breakpoint("small-screen")) {
  #dashboard {
    max-height: fit-content;
    height: fit-content;
    overflow-y: auto;
    margin-left: calc(#{$sidebar-width} + #{$bg-padding-x} - 5%);
    min-width: calc(100vw - #{$sidebar-width} - #{3 * $bg-padding-x} + 5%);
  }

  .dashboard-content {
    min-height: 87vh;
    flex-wrap: wrap;
  }

  .settings-content {
    .dashboard-content {
      flex-wrap: nowrap !important;
    }
  }

  .dashboard-preview {
    min-height: 87vh;
  }

  .dashboard-content,
  .dashboard-preview {
    width: 100%;
    max-height: fit-content !important;
    height: fit-content !important;
  }

  .search-module {
    margin-left: 0 !important;
    flex-wrap: wrap;
    flex-grow: 1;

    .react-custom-select {
      width: 45%;
    }
  }

  .employees-tables,
  .company-partners-tables {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .employee-section,
  .partner-section {
    width: 100% !important;
  }
}

@media (max-width: breakpoint("laptop")) {
  #dashboard {
    max-height: fit-content;
    height: fit-content;
    margin: 10px 0 0 0;
    overflow-y: auto;
    flex-direction: column;
  }

  .dashboard-content {
    min-height: 91vh;
  }

  .dashboard-content,
  .dashboard-preview {
    width: 100%;
    max-height: fit-content;
    height: fit-content;
    min-height: fit-content;
  }

  .dashboard-content {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .resto-overview-item {
    width: 100%;

    &:first-child > div:first-child {
      flex-direction: column;
      gap: 10px;
    }
  }

  .search-module {
    margin-left: 0 !important;
    flex-wrap: wrap;
    flex-grow: 1;

    .react-custom-select {
      width: 45%;
    }
  }

  .partner-title {
    margin-left: 0 !important;
  }

  .partner-header {
    justify-content: space-between;
    gap: 10px;

    .dashboard-search-bar {
      width: 100%;
    }
  }

  #settings {
    .dashboard-content {
      padding: 0 !important;
    }

    .img-settings-container {
      padding: 60px 0 30px 0;
    }

    .settings-content {
      height: fit-content;
    }
  }

  .employees-tables,
  .company-partners-tables {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

@media (max-width: breakpoint("tablet")) {
  #dashboard {
    max-height: fit-content;
    height: fit-content;
    margin: 10px 0 0 0;
    overflow-y: auto;
    flex-direction: column;
  }

  .dashboard-content,
  .dashboard-preview {
    width: 100%;
    max-height: fit-content;
    height: fit-content;
  }

  .dashboard-content {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .resto-overview-item {
    width: 100%;

    &:first-child > div:first-child {
      flex-direction: column;
      gap: 10px;
    }
  }

  .search-module {
    margin-left: 0 !important;
    flex-wrap: wrap;
    flex-grow: 1;

    .react-custom-select {
      width: 45%;
    }
  }

  .partner-title {
    margin-left: 0 !important;
  }

  .partner-header {
    justify-content: space-between;
    gap: 10px;

    .dashboard-search-bar {
      width: 100%;
    }
  }

  #settings {
    .dashboard-content {
      padding: 0 !important;
    }

    .img-settings-container {
      padding: 60px 0 30px 0;
    }

    .settings-content {
      height: fit-content;
    }
  }

  .employees-tables,
  .company-partners-tables {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

@media (max-width: breakpoint("phone")) {
  #dashboard {
    max-height: fit-content;
    height: fit-content;
    margin: 10px 0 0 0;
    overflow-y: auto;
    flex-direction: column;
  }

  .dashboard-content,
  .dashboard-preview {
    width: 100%;
    max-height: fit-content;
    height: fit-content;
  }

  .dashboard-content {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .resto-overview-item {
    width: 100%;

    &:first-child > div:first-child {
      flex-direction: column;
      gap: 10px;
    }
  }

  .company-overview-item {
    width: 100%;
  }

  .search-module {
    margin-left: 0 !important;
    flex-wrap: wrap;
    flex-grow: 1;

    .react-custom-select {
      width: 45%;
    }
  }

  .partner-title {
    margin-left: 0 !important;
  }

  .partner-header {
    justify-content: space-between;
    gap: 10px;

    .dashboard-search-bar {
      width: 100%;
    }
  }

  #settings {
    .dashboard-content {
      padding: 0 !important;
    }

    .settings-content {
      height: fit-content;
    }

    .img-settings-container {
      padding: 60px 0 30px 0;
    }
  }

  .employees-tables,
  .company-partners-tables {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}
