#register {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 4rem 0;
}

.register-step {
    width: 70%;
}

.step-header {
    margin-bottom: 1rem;
}

@media (max-width: breakpoint('small-screen')) {
    .register-step {
        width: 90%;
    }
}

@media (max-width: breakpoint('laptop')) {
    .register-step {
        width: 100%;
    }
}

@media (max-width: breakpoint('tablet')) {
    .register-step {
        width: 100%;

        .form-group {
            width: 100% !important;
            padding: 0 !important;
        }
    }
}

@media (max-width: breakpoint('phone')) {
    #register.content {
        min-height: 80vh;
    }

    .register-back {
        display: none;
    }

    #register .btn-group span {
        display: none;
    }

    #register .btn-group svg {
        margin: 0 !important;
    }    
}